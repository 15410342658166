import { NavItem } from '../../vertical/sidebar/nav-item/nav-item';

export const navItemsAdministrativeDirection: NavItem[] = [
  {
    navCap: 'Menú',
  },
  {
    displayName: 'Inicio',
    iconName: 'home',
    route: 'administracion',
  },
  {
    displayName: 'Personal',
    iconName: 'user',
    route: 'personal',
    children: [
      {
        displayName: 'Doctores',
        iconName: 'point',
        route: '/administracion/doctores',
      },
      {
        displayName: 'Enfermeros',
        iconName: 'point',
        route: '/administracion/enfermeros',
      },
      {
        displayName: 'Usuarios',
        iconName: 'point',
        route: '/administracion/usuarios',
      },
      {
        displayName: 'Proveedores',
        iconName: 'point',
        route: '/administracion/proveedores',
      },
    ],
  },
  {
    displayName: 'Consultorios',
    iconName: 'stethoscope',
    route: 'administracion/consultas-externas',
  },
  {
    displayName: 'Pagos de consultas',
    iconName: 'coin',
    route: 'administracion/pago-consultas-atencion-interna',
  },
  {
    displayName: 'Medicamentos',
    iconName: 'user',
    route: 'personal',
    children: [
      {
        displayName: 'Inventario de producto',
        iconName: 'point',
        route: '/almacen/inventario',
      }      
    ],
  },
];


