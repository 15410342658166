import {
  Component,
  Output,
  EventEmitter,
  Input,
  inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../../vertical/sidebar/sidebar-data';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouterModule } from '@angular/router';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { BrandingComponent } from '../../vertical/sidebar/branding.component';
import { CommonModule, NgFor, NgForOf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppHorizontalSidebarComponent } from '../sidebar/sidebar.component';
import { AuthLoginService } from 'src/app/apps/authentication/services/auth-login.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Message, SendMessageChat } from './message.interface';
import { environment } from 'src/environments/environment';
import { NewNotificationService } from '../../shared/services/new-notification.service';


interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface apps {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
  selector: 'app-horizontal-header',
  standalone: true,
  imports: [AppHorizontalSidebarComponent, RouterModule, TablerIconsModule, MaterialModule, BrandingComponent, NgFor, CommonModule],
  templateUrl: './header.component.html',
})
export class AppHorizontalHeaderComponent {
  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  timeoutId: any;

  role = signal<string | null>('')
  username = signal<string | null>('')
  token = signal<string | null>('')
  hidenBadge = signal<boolean>(true);
  typeRole: string | null;
  dataNotification: any;
  messageNotification = 'Nueva notificación de Médica del Salvador';

  baseURL = environment.API_URL;

  ngOnInit(): void {
    this.getHeaders();
    this.getNotificationsOnLocalStorage()
    this.getTypeRole()

    this.connectionExternal.start()
      .then(_ => {
      }).catch(error => {
        return console.error(error);
      });

    this.connectionInternal.start()
      .then(_ => {
      }).catch(error => {
        return console.error(error);
      });

  }

  getHeaders() {
    this.role.set(localStorage.getItem('role'));
    this.username.set(localStorage.getItem('username'));
    this.token.set(localStorage.getItem('token'));
  }

  getNotificationsOnLocalStorage() {
    const storedArray = localStorage.getItem('notifications');
    if (storedArray) {
      this.notifications = JSON.parse(storedArray);
    }

    if (this.notifications.length) {
      this.hidenBadge.set(false);
    }
  }

  authLoginService = inject(AuthLoginService)

  private connectionExternal: HubConnection;
  private connectionInternal: HubConnection;

  constructor(
    private vsidenav: CoreService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public newNotificationService: NewNotificationService
  ) {
    // translate.setDefaultLang('en');

    this.connectionExternal = new HubConnectionBuilder()
      .withUrl(`${this.baseURL}/external-consultation-hub`,
        {
          accessTokenFactory: () => `${this.token()}`
        })
      .build();

    this.connectionExternal.on("ReceiveExternalConsultationCreated", (resp) => {
      this.dataNotification = resp;
      this.messageNotification = 'MDS: Hay una nueva consulta externa para atender';
      this.newNotificationService.sendEvent(this.dataNotification);
      this.newNotification(this.dataNotification);
    })

    this.connectionExternal.on("CancelExternalConsultationCreated", (resp) => {
      this.dataNotification = resp;
      this.newNotificationService.sendEvent(this.dataNotification);
    })



    this.connectionInternal = new HubConnectionBuilder()
      .withUrl(`${this.baseURL}/internal-consultation-hub`,
        {
          accessTokenFactory: () => `${this.token()}`
        })
      .build();

    this.connectionInternal.on("CreateInternalConsultation", (resp) => {      
      this.dataNotification = resp;
      this.messageNotification = 'MDS: Hay una nueva consulta interna para atender';
      this.newNotificationService.sendEvent(this.dataNotification);
      this.newNotification(this.dataNotification);
    });

    this.connectionInternal.on("ReAssignConsultation", (resp) => {      
      this.dataNotification = resp;

      if (this.dataNotification.doctor.user.userName == this.username()) {
        this.messageNotification = 'MDS: Hay una nueva consulta interna para atender';
        this.newNotificationService.sendEvent(this.dataNotification);
        this.newNotification(this.dataNotification);
      } else {
        this.newNotificationService.sendEvent(this.dataNotification);
      }
    })

    this.connectionInternal.on("CancelInternalConsultation", (resp) => {
      this.dataNotification = resp;
      this.newNotificationService.sendEvent(this.dataNotification);
    })

    this.connectionInternal.on("SendMessageChat", (resp: SendMessageChat) => {
      this.dataNotification = resp;          
      const internalConsultationId = localStorage.getItem('internalConsultationId')            
      if(internalConsultationId == this.dataNotification.internalConsultationId){
        this.newNotificationService.sendEvent(this.dataNotification);
        this.showNotificationMessageChat()
      }          
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppHorizontalSearchDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  // changeLanguage(lang: any): void {
  //   this.translate.use(lang.code);
  //   this.selectedLanguage = lang;
  // }

  notifications: Message[] = [];

  newNotification(newConsult: any) {
    if (this.getTypeRole()) return;
    this.notifications = [newConsult, ...this.notifications]
    localStorage.setItem('notifications', JSON.stringify(this.notifications));
    if (this.notifications.length) {
      this.hidenBadge.set(false);
    }
    this.showNotification(newConsult);
  }

  getTypeRole(): boolean {
    this.typeRole = localStorage.getItem('role');
    if (this.typeRole == 'Administrator') {
      return true
    }
    return false
  }

  showNotification(newConsult: any) {
    if (Notification.permission === 'granted') {

      const notification = new Notification(this.messageNotification, {
        body: 'Ve al sistema para atender la notificación.',
        icon: '/assets/images/logos/medica-del-salvador.png',
      });

      const audio = new Audio('/assets/songs/bright-ringtone-loop-151768.mp3');
      audio.play();

      notification.onclick = () => {
        notification.close();
        audio.pause();
      };

      this.timeoutId = setTimeout(() => {
        notification.close();
        audio.pause();
      }, 13000);
    } else {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          const notification = new Notification(this.messageNotification, {
            body: 'Ve al sistema para atender la notificación.',
            icon: '/assets/images/logos/medica-del-salvador.png',
          });

          const audio = new Audio('/assets/songs/bright-ringtone-loop-151768.mp3');
          audio.play();
          notification.onclick = () => {
            notification.close();
            audio.pause();
          };

          this.timeoutId = setTimeout(() => {
            notification.close();
            audio.pause();
          }, 13000);
        }
      });
    }
  }

  showNotificationMessageChat() {
    if (Notification.permission === 'granted') {

      const notification = new Notification('MDS: Nueva nota de enfermería', {
        body: 'Ve al sistema para ver el mensaje',
        icon: '/assets/images/logos/medica-del-salvador.png',
      });

      const audio = new Audio('/assets/songs/livechat-129007.mp3');
      audio.play();

      notification.onclick = () => {
        notification.close();
        audio.pause();
      };

      // this.timeoutId = setTimeout(() => {
      //   notification.close();
      //   audio.pause();
      // }, 13000);
    } else {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          const notification = new Notification('MDS: Nueva nota de enfermería', {
            body: 'Ve al sistema para atender la notificación.',
            icon: '/assets/images/logos/medica-del-salvador.png',
          });

          const audio = new Audio('/assets/songs/livechat-129007.mp3');
          audio.play();
          notification.onclick = () => {
            notification.close();
            audio.pause();
          };

          // this.timeoutId = setTimeout(() => {
          //   notification.close();
          //   audio.pause();
          // }, 13000);
        }
      });
    }
  }



  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  deleteNotification(indexNotification: any) {
    this.notifications.splice(indexNotification, 1);
    localStorage.setItem('notifications', JSON.stringify(this.notifications));
    if (this.notifications.length == 0) {
      this.hidenBadge.set(true);
    }
  }

  deleteAllNotifications() {
    this.notifications = []
    localStorage.setItem('notifications', JSON.stringify(this.notifications));
    if (this.notifications.length == 0) {
      this.hidenBadge.set(true);
    }
  }

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'Mi perfil',
      subtitle: 'Editar cuenta',
      link: '/consulta/editar-cuenta',
    },
    // {
    //   id: 2,
    //   img: '/assets/images/svgs/icon-inbox.svg',
    //   title: 'Mis mensajes',
    //   subtitle: 'Ver',
    //   link: '/apps/email/inbox',
    // },
    // {
    //   id: 3,
    //   img: '/assets/images/svgs/icon-tasks.svg',
    //   title: 'Tareas',
    //   subtitle: 'Ver que hay por hacer',
    //   link: '/apps/taskboard',
    // },
  ];

  apps: apps[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-dd-chat.svg',
      title: 'Chat Application',
      subtitle: 'Messages & Emails',
      link: '/apps/chat',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-dd-cart.svg',
      title: 'eCommerce App',
      subtitle: 'Buy a Product',
      link: '/apps/email/inbox',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-dd-invoice.svg',
      title: 'Invoice App',
      subtitle: 'Get latest invoice',
      link: '/apps/invoice',
    },
    {
      id: 4,
      img: '/assets/images/svgs/icon-dd-date.svg',
      title: 'Calendar App',
      subtitle: 'Get Dates',
      link: '/apps/calendar',
    },
    {
      id: 5,
      img: '/assets/images/svgs/icon-dd-mobile.svg',
      title: 'Contact Application',
      subtitle: '2 Unsaved Contacts',
      link: '/apps/contacts',
    },
    {
      id: 6,
      img: '/assets/images/svgs/icon-dd-lifebuoy.svg',
      title: 'Tickets App',
      subtitle: 'Create new ticket',
      link: '/apps/tickets',
    },
    {
      id: 7,
      img: '/assets/images/svgs/icon-dd-message-box.svg',
      title: 'Email App',
      subtitle: 'Get new emails',
      link: '/apps/email/inbox',
    },
    {
      id: 8,
      img: '/assets/images/svgs/icon-dd-application.svg',
      title: 'Courses',
      subtitle: 'Create new course',
      link: '/apps/courses',
    },
  ];

  quicklinks: quicklinks[] = [
    {
      id: 1,
      title: 'Pricing Page',
      link: '/theme-pages/pricing',
    },
    {
      id: 2,
      title: 'Authentication Design',
      link: '/authentication/side-login',
    },
    {
      id: 3,
      title: 'Register Now',
      link: '/authentication/side-register',
    },
    {
      id: 4,
      title: '404 Error Page',
      link: '/authentication/error',
    },
    {
      id: 5,
      title: 'Notes App',
      link: '/apps/notes',
    },
    {
      id: 6,
      title: 'Employee App',
      link: '/apps/employee',
    },
    {
      id: 7,
      title: 'Todo Application',
      link: '/apps/todo',
    },
    {
      id: 8,
      title: 'Treeview',
      link: '/theme-pages/treeview',
    },
  ];

  public router = inject(Router)

  // filtered = this.navItemsData.find((obj) => {
  //   return obj.displayName == this.searchinput;
  // });

  goToChat() {
    this.router.navigateByUrl('/apps/chat');
  }

  goToCalendar() {
    this.router.navigateByUrl('/apps/calendar');
  }

  goToEmail() {
    this.router.navigateByUrl('/apps/email/inbox');
  }

  logOutSession() {
    this.authLoginService.logout()
  }




}

@Component({
  selector: 'app-search-dialog',
  standalone: true,
  imports: [RouterModule, MaterialModule, TablerIconsModule, FormsModule, NgForOf, CommonModule, NgFor],
  templateUrl: 'search-dialog.component.html',
})
export class AppHorizontalSearchDialogComponent {
  searchText: string = '';
  navItems = navItems;

  navItemsData = navItems.filter((navitem) => navitem.displayName);

  // filtered = this.navItemsData.find((obj) => {
  //   return obj.displayName == this.searchinput;
  // });  

}