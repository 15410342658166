import { Injectable, signal } from '@angular/core';

import { jwtDecode } from "jwt-decode";
import { DecodeJWToken } from '../interfaces/decodeToken';

@Injectable({
  providedIn: 'root'
})
export class DecodeTokenService {

  decodeToken(token: string): DecodeJWToken | null {
    try {
      // const decoded:DecodeJWToken = jwtDecode(token);
      const decoded = signal<DecodeJWToken>(jwtDecode(token))
      return decoded();
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      return null;
    }
  }  
  

  
}
