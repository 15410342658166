import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  inject,
  signal,
} from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { MatDialog } from '@angular/material/dialog';
import { navItems } from '../sidebar/sidebar-data';
import { TranslateService } from '@ngx-translate/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { CommonModule, NgForOf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthLoginService } from 'src/app/apps/authentication/services/auth-login.service';
import { Message } from '../../horizontal/header/message.interface';
import { environment } from 'src/environments/environment';
import { ChageItemsMenuService } from '../../shared/services/chage-items-menu.service';
import { NewNotificationService } from '../../shared/services/new-notification.service';


interface notifications {
  id: number;
  img: string;
  title: string;
  subtitle: string;
}

interface profiledd {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface apps {
  id: number;
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

interface quicklinks {
  id: number;
  title: string;
  link: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule, NgScrollbarModule, TablerIconsModule, MaterialModule],
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {

  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  showFiller = false;

  public selectedLanguage: any = {
    // language: 'English',
    // code: 'en',
    // type: 'US',
    // icon: '/assets/images/flag/icon-flag-en.svg',
    language: 'Español',
    code: 'es',
    type: 'ES',
    icon: '/assets/images/flag/icon-flag-es.svg',

  };

  public languages: any[] = [
    {
      language: 'Español',
      code: 'es',
      icon: '/assets/images/flag/icon-flag-es.svg',
    },
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: '/assets/images/flag/icon-flag-en.svg',
    },
    {
      language: 'Français',
      code: 'fr',
      icon: '/assets/images/flag/icon-flag-fr.svg',
    },
    {
      language: 'German',
      code: 'de',
      icon: '/assets/images/flag/icon-flag-de.svg',
    },
  ];

  authLoginService = inject(AuthLoginService)

  private connection: HubConnection;

  timeoutId: any;
  role = signal<string | null>('')
  username = signal<string | null>('')
  token = signal<string | null>('')
  hidenBadge = signal<boolean>(true);
  baseURL = environment.API_URL;

  dataNotification: any;

  constructor(
    private vsidenav: CoreService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public newNotificationService: NewNotificationService
  ) {
    // translate.setDefaultLang('en');
    this.connection = new HubConnectionBuilder()
      .withUrl(`${this.baseURL}/external-consultation-hub`,
      {
        accessTokenFactory: () => `${this.token()}`
      })
      .build();

    this.connection.on("ReceiveExternalConsultationCreated", (message) => {
      this.dataNotification = message;
      this.newNotificationService.sendEvent(this.dataNotification);
      this.newNotification(this.dataNotification);
    })
  }

  ngOnInit(): void {
    this.getHeaders();
    this.getNotificationsOnLocalStorage()

    this.connection.start()
      .then(_ => {
      }).catch(error => {
        return console.error(error);
      });

  }

  getHeaders() {
    this.role.set(localStorage.getItem('role'));
    this.username.set(localStorage.getItem('username'));
    this.token.set(localStorage.getItem('token'));
  }

  getNotificationsOnLocalStorage() {
    const storedArray = localStorage.getItem('notifications');
    if (storedArray) {
      this.notifications = JSON.parse(storedArray);
    }

    if (this.notifications.length) {
      this.hidenBadge.set(false);
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppSearchDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  notifications: Message[] = [];

  newNotification(newConsult: any) {    
    console.log(newConsult);
    this.notifications.push(newConsult)
    localStorage.setItem('notifications', JSON.stringify(this.notifications));    
    if (this.notifications.length) {
      this.hidenBadge.set(false);
    }    
    this.showNotification();
  }

  showNotification() {    
    if (Notification.permission === 'granted') {      
      const notification = new Notification('Nueva notificación de Médica del Salvador', {
        body: 'Ve al sistema y verifica la notificación.',
        icon: '/assets/images/logos/medica-del-salvador.png',
      });

      const audio = new Audio('/assets/songs/bright-ringtone-loop-151768.mp3');
      audio.play();

      notification.onclick = () => {
        notification.close();
        audio.pause();
      };

      this.timeoutId = setTimeout(() => {
        // Puedes ajustar la duración deseada antes de cerrar la notificación automáticamente
        notification.close();
        // Puedes detener el sonido aquí si lo deseas
        audio.pause();
      }, 13000);
    } else {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {          
          const notification = new Notification('Nueva notificación de Médica del Salvador', {
            body: 'Ve al sistema y verifica la notificación.',
            icon: '/assets/images/logos/medica-del-salvador.png',
          });          
          
          const audio = new Audio('/assets/songs/bright-ringtone-loop-151768.mp3');
          audio.play();

          notification.onclick = () => {
            notification.close();
            audio.pause();
          };

          this.timeoutId = setTimeout(() => {
            notification.close();
            audio.pause();
          }, 13000);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.clearTimeout();
  }

  clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  deleteNotification(indexNotification: any) {
    this.notifications.splice(indexNotification, 1);
    localStorage.setItem('notifications', JSON.stringify(this.notifications));
    if (this.notifications.length == 0) {
      this.hidenBadge.set(true);
    }
  }

  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  logOutSession() {
    this.authLoginService.logout()
  }

  profiledd: profiledd[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-account.svg',
      title: 'Mi perfil',
      subtitle: 'Editar cuenta',
      link: '/consulta/editar-cuenta',
    },
    // {
    //   id: 2,
    //   img: '/assets/images/svgs/icon-inbox.svg',
    //   title: 'My Inbox',
    //   subtitle: 'Messages & Email',
    //   link: '/apps/email/inbox',
    // },
    // {
    //   id: 3,
    //   img: '/assets/images/svgs/icon-tasks.svg',
    //   title: 'Lista de pendientes',
    //   subtitle: 'Pendientes para hoy',
    //   link: '/apps/taskboard',
    // },
  ];

  apps: apps[] = [
    {
      id: 1,
      img: '/assets/images/svgs/icon-dd-chat.svg',
      title: 'Chat Application',
      subtitle: 'Messages & Emails',
      link: '/apps/chat',
    },
    {
      id: 2,
      img: '/assets/images/svgs/icon-dd-cart.svg',
      title: 'Todo App',
      subtitle: 'Completed task',
      link: '/apps/todo',
    },
    {
      id: 3,
      img: '/assets/images/svgs/icon-dd-invoice.svg',
      title: 'Invoice App',
      subtitle: 'Get latest invoice',
      link: '/apps/invoice',
    },
    {
      id: 4,
      img: '/assets/images/svgs/icon-dd-date.svg',
      title: 'Calendar App',
      subtitle: 'Get Dates',
      link: '/apps/calendar',
    },
    {
      id: 5,
      img: '/assets/images/svgs/icon-dd-mobile.svg',
      title: 'Contact Application',
      subtitle: '2 Unsaved Contacts',
      link: '/apps/contacts',
    },
    {
      id: 6,
      img: '/assets/images/svgs/icon-dd-lifebuoy.svg',
      title: 'Tickets App',
      subtitle: 'Create new ticket',
      link: '/apps/tickets',
    },
    {
      id: 7,
      img: '/assets/images/svgs/icon-dd-message-box.svg',
      title: 'Email App',
      subtitle: 'Get new emails',
      link: '/apps/email/inbox',
    },
    {
      id: 8,
      img: '/assets/images/svgs/icon-dd-application.svg',
      title: 'Courses',
      subtitle: 'Create new course',
      link: '/apps/courses',
    },
  ];

  quicklinks: quicklinks[] = [
    {
      id: 1,
      title: 'Pricing Page',
      link: '/theme-pages/pricing',
    },
    {
      id: 2,
      title: 'Authentication Design',
      link: '/authentication/side-login',
    },
    {
      id: 3,
      title: 'Register Now',
      link: '/authentication/side-register',
    },
    {
      id: 4,
      title: '404 Error Page',
      link: '/authentication/error',
    },
    {
      id: 5,
      title: 'Notes App',
      link: '/apps/notes',
    },
    {
      id: 6,
      title: 'Employee App',
      link: '/apps/employee',
    },
    {
      id: 7,
      title: 'Todo Application',
      link: '/apps/todo',
    },
    {
      id: 8,
      title: 'Treeview',
      link: '/theme-pages/treeview',
    },
  ];
}

@Component({
  selector: 'search-dialog',
  standalone: true,
  imports: [
    RouterModule,
    MaterialModule,
    TablerIconsModule,
    FormsModule,
    NgForOf,
  ],
  templateUrl: 'search-dialog.component.html',
})
export class AppSearchDialogComponent {
  searchText: string = '';
  navItems = navItems;

  navItemsData = navItems.filter((navitem) => navitem.displayName);

  // filtered = this.navItemsData.find((obj) => {
  //   return obj.displayName == this.searchinput;
  // });
}
