import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChageItemsMenuService {

  private eventToChangeItemsMenu = new Subject<void>();

  sendEvent() {        
    this.eventToChangeItemsMenu.next();        
  }

  getEvent() {    
    return this.eventToChangeItemsMenu.asObservable();    
  }

  ngOnDestroy(): void {
    this.getEvent();
    this.eventToChangeItemsMenu;
  }
  
}
