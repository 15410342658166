import { NavItem } from '../../vertical/sidebar/nav-item/nav-item';

export const navItemsConsultory: NavItem[] = [
  {
    navCap: 'Desk',
  },  
  {
    displayName: 'Pacientes en hospital',
    iconName: 'heart-rate-monitor',
    route: 'consulta/pacientes-en-hospital',
  },     
  {
    displayName: 'Pacientes en consultorio',
    iconName: 'stethoscope',
    route: 'consulta/pacientes-en-consultorio',
  },     
  {
    displayName: 'Pacientes en atención interna',
    iconName: 'emergency-bed',
    route: 'consulta/pacientes-en-atencion-interna',
  },   
  {
    displayName: 'Consulta actual',
    iconName: 'clipboard-heart',
    route: 'consulta/consulta-actual',
  },
  {
    displayName: 'Mis pacientes',
    iconName: 'users',
    route: 'consulta/lista-de-pacientes',
  }, 
  // {
  //   displayName: 'Solicitar ayuda',
  //   iconName: 'nurse',
  //   route: 'consulta/solicitar-ayuda',
  // }, 
];

export const navItemsDoctorConsultoryWithOutCurrentConsulting: NavItem[] = [
  {
    navCap: 'Desk',
  },  
  {
    displayName: 'Pacientes en hospital',
    iconName: 'heart-rate-monitor',
    route: 'consulta/pacientes-en-hospital',
  },     
  {
    displayName: 'Pacientes en consultorio',
    iconName: 'stethoscope',
    route: 'consulta/pacientes-en-consultorio',
  },
  {
    displayName: 'Pacientes en atención interna',
    iconName: 'emergency-bed',
    route: 'consulta/pacientes-en-atencion-interna',
  },     
  {
    displayName: 'Mis pacientes',
    iconName: 'users',
    route: 'consulta/lista-de-pacientes',
  },
  // {
  //   displayName: 'Solicitar ayuda',
  //   iconName: 'nurse',
  //   route: 'consulta/solicitar-ayuda',
  // },    
];

export const navItemsConsultoryWithExternalAndInternal: NavItem[] = [
  {
    navCap: 'Desk',
  },  
  {
    displayName: 'Pacientes en hospital',
    iconName: 'heart-rate-monitor',
    route: 'consulta/pacientes-en-hospital',
  },     
  {
    displayName: 'Pacientes en consultorio',
    iconName: 'stethoscope',
    route: 'consulta/pacientes-en-consultorio',
  },     
  {
    displayName: 'Pacientes en atención interna',
    iconName: 'emergency-bed',
    route: 'consulta/pacientes-en-atencion-interna',
  },   
  {
    displayName: 'Consulta externa actual',
    iconName: 'clipboard-heart',
    route: 'consulta/consulta-actual',
  },
  {
    displayName: 'Consulta interna actual',
    iconName: 'clipboard-heart',
    route: 'consulta/consulta-atencion-interna',
  },
  {
    displayName: 'Mis pacientes',
    iconName: 'users',
    route: 'consulta/lista-de-pacientes',
  }, 
  // {
  //   displayName: 'Solicitar ayuda',
  //   iconName: 'nurse',
  //   route: 'consulta/solicitar-ayuda',
  // }, 
];

export const navItemsConsultoryWithInternalWithOutExternal: NavItem[] = [
  {
    navCap: 'Desk',
  },  
  {
    displayName: 'Pacientes en hospital',
    iconName: 'heart-rate-monitor',
    route: 'consulta/pacientes-en-hospital',
  },     
  {
    displayName: 'Pacientes en consultorio',
    iconName: 'stethoscope',
    route: 'consulta/pacientes-en-consultorio',
  },     
  {
    displayName: 'Pacientes en atención interna',
    iconName: 'emergency-bed',
    route: 'consulta/pacientes-en-atencion-interna',
  },     
  {
    displayName: 'Consulta interna actual',
    iconName: 'clipboard-heart',
    route: 'consulta/consulta-atencion-interna',
  },
  {
    displayName: 'Mis pacientes',
    iconName: 'users',
    route: 'consulta/lista-de-pacientes',
  }, 
  // {
  //   displayName: 'Solicitar ayuda',
  //   iconName: 'nurse',
  //   route: 'consulta/solicitar-ayuda',
  // }, 
];
