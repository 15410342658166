import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SendMessageChat } from '../../horizontal/header/message.interface';

@Injectable({
  providedIn: 'root'
})
export class NewNotificationService {

  private newNotification = new Subject<any>();

  sendEvent(dataNotifcation:any) {        
    this.newNotification.next(dataNotifcation);        
  }

  getEvent():Observable<SendMessageChat> {    
    return this.newNotification.asObservable();    
  }

  ngOnDestroy(): void {
    this.getEvent();
    this.newNotification;
  }
}
