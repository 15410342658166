import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [  
  {
    navCap: 'Menú',
  },
  {
    displayName: 'Dirección',
    iconName: 'home',
    route: 'dashboards',
    children: [
      {
        displayName: 'Dirección administrativa',
        iconName: 'point',
        route: 'dashboards/dashboard1',
      },
      {
        displayName: 'Dirección médica',
        iconName: 'point',
        route: 'dashboards/dashboard2',
      },
    ],
  },  
  {
    displayName: 'Farmacia',
    iconName: 'components',
    route: 'farmacia',
    ddType: '',
    children: [
      {
        displayName: 'Pedidos de hospital',
        iconName: 'point',
        route: '/farmacia/pedidos-de-hospital',
      },
      {
        displayName: 'Pedidos de consultorio',
        iconName: 'point',
        route: '/farmacia/pedidos-de-consultorio',
      },      
      {
        displayName: 'Ventas',
        iconName: 'point',
        route: '/farmacia/ventas',
      },
      {
        displayName: 'Surtir farmacia',
        iconName: 'point',
        route: '/farmacia/surtir-farmacia',
      }                              
    ],
  },
  {
    displayName: 'Almacén',
    iconName: 'clipboard',
    route: 'almacen',
    children: [      
      {
        displayName: 'Inventario',
        iconName: 'point',
        route: 'almacen/inventario',
      }, 
      {
        displayName: 'Pedidos',
        iconName: 'point',
        route: 'almacen/pedidos',
      },
      {
        displayName: 'Surtir almacen',
        iconName: 'point',
        route: 'almacen/surtir-almacen',
      },
      {
        displayName: 'Proveedores',
        iconName: 'point',
        route: '/proveedores',
      }                          
    ],
  },
  {
    displayName: 'Consultas',
    iconName: 'layout',
    route: 'consulta',
    children: [ 
      {
        displayName: 'Escritorio',
        iconName: 'point',
        route: 'consulta/escritorio',
      },     
      {
        displayName: 'Nueva consulta',
        iconName: 'point',
        route: 'consulta/nueva-consulta',
      },
      {
        displayName: 'Lista de pacientes',
        iconName: 'point',
        route: 'consulta/lista-de-pacientes',
      },      
    ],
  }, 
  {
    displayName: 'Hospital',
    iconName: 'file-description',
    route: 'forms',
    children: [
      {
        displayName: 'Registro',
        iconName: 'point',
        route: 'forms/forms-elements',
        children: [
          {
            displayName: 'Autocomplete',
            iconName: 'point',
            route: 'forms/forms-elements/autocomplete',
          }          
        ],
      },   
      {
        displayName: 'Cobranza',
        iconName: 'point',
        route: 'forms/forms-elements/autocomplete',
        // children: [
        //   {
        //     displayName: 'Autocomplete',
        //     iconName: 'point',
        //     route: 'forms/forms-elements/autocomplete',
        //   },
          
        // ],
      },      
    ],
  },   
  {
    displayName: 'Enfermería',
    iconName: 'layout',
    route: 'tables',
    children: [      
      {
        displayName: 'Jefatura de enfermería',
        iconName: 'point',
        route: 'tables/http-table',
      },
      {
        displayName: 'Jefatura de quirófano',
        iconName: 'point',
        route: 'tables/mix-table',
      },      
    ],
  },   
];
