import { Injectable, inject, signal } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { Roles } from '../interfaces/typeUsers'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginResponse } from '../interfaces/login-response';
import { DecodeTokenService } from './decode-token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginService {

  private baseUrl = environment.API_URL;
  private http = inject(HttpClient);
  private router = inject(Router)
  decodeTokenService = inject(DecodeTokenService);
  typeRole = signal<string | undefined>('');

  login(authData: any): Observable<LoginResponse> {
    const url = `${this.baseUrl}/api/auth/login`

    return this.http.post<LoginResponse>(url, authData)
      .pipe(
        map((resp: any) => {
          const decodeToken = this.decodeTokenService.decodeToken(resp.token)
          this.typeRole.set(decodeToken?.['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'])
          this.saveToken(resp.token, decodeToken?.exp, this.typeRole(), decodeToken?.username, decodeToken?.sub , decodeToken?.permission);
          return resp;
        }),
        catchError(err => throwError(() => {
          return err;
        }
        ))
      )
  }

  checkToken(): boolean {        
    const token = localStorage.getItem('token');
    const exp = Number(localStorage.getItem('exp'));              
    const expirationDate = token ? new Date(exp * 1000) : null;
    return expirationDate ? expirationDate > new Date() : false;    
  }

  tokenInvalid(){
    const token = localStorage.getItem('token');
    const exp = Number(localStorage.getItem('exp'));              
    const expirationDate = token ? new Date(exp * 1000) : null;
    // return expirationDate ? expirationDate > new Date() : false;    
    if (expirationDate) {
      if (expirationDate > new Date()) {
        return true;
      } else {
        this.logout();
        return false;
      }
    } else {
      this.logout();
      return false;
    }
  }

  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('permission');
    localStorage.removeItem('exp');
    localStorage.removeItem('patientId');
    localStorage.removeItem('username');
    localStorage.removeItem('sub');
    localStorage.removeItem('diagnosis');
    localStorage.removeItem('curesDescription');
    localStorage.removeItem('age');
    localStorage.removeItem('notifications');
    localStorage.removeItem('internalConsultationId');
    this.router.navigateByUrl('/autenticacion/ingresar')
  }

  private saveToken(token: string, exp: any, role: any, username: any, sub:any, permission: any): void {
    localStorage.setItem('token', token);
    localStorage.setItem('exp', exp);
    localStorage.setItem('role', role);
    localStorage.setItem('username', username);
    localStorage.setItem('sub', sub);
    localStorage.setItem('permission', permission);
  }

}


