import { NgIf } from '@angular/common';
import { Component, signal } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-branding',
  standalone: true,
  imports: [NgIf],
  template: `
    <div class="branding">
      <a [href]="routeImage()" *ngIf="options.theme === 'light'" tabindex="-1">
        <img
          src="./assets/images/logos/medica-del-salvador2.png"
          class="align-middle size-image"
          alt="logo"          
        />
      </a>
      <a href="/" *ngIf="options.theme === 'dark'" tabindex="-1"> 
        <img
          src="./assets/images/logos/medica-del-salvador2.png"
          class="align-middle"
          alt="logo"
        />
      </a>
    </div>
  `,
})
export class BrandingComponent {
  options = this.settings.getOptions();
  routeImage = signal<string | null>('/autenticacion/ingresar');

  ngOnInit(): void {    
    if(localStorage.getItem('role') == 'Administrator'){
      this.routeImage.set('/administracion/consultas-externas');      
    }

    if(localStorage.getItem('role') == 'Doctor'){
      this.routeImage.set('/consulta/pacientes-en-consultorio')      
    }
    
    
  }
 
  constructor(private settings: CoreService) {} 
}
